import React from "react";
import SEO from "../../components/seo";
import Blog from "../../components/Blog";
import Par from "../../components/Par";
import H1 from "../../components/h1";

const title = "Inflammatory Bowel Disease (Crohn's & Colitis)";

// Download link - http://www.fbrennangastro.com.au/downloads/gesa_ibd.pdf

export default () => (
	<Blog title={title}>
		<SEO
			title={title}
			keywords={[title, "IBD", "Ulcerative colitis, IBD treatment, IBD causes"]}
			type="article"
			description="Inflammatory bowel disease (IBD), Crohn's disease and ulcerative colitis - what they all mean, causes and treatment options"
		/>
		<H1>{title}</H1>
		<Par>
			The term inflammatory bowel disease (IBD) covers a group of disorders in which the intestines become inflamed (red
			and swollen). The major types of IBD are Crohn&apos;s disease and ulcerative colitis. Symptoms include pain,
			diarrhoea and tiredness. IBD is not caused by diet or allergies to certain food components.
		</Par>
		<Par>
			Crohn&apos;s disease and ulcerative colitis affect the bowel in different ways. Crohn&apos;s disease causes
			inflammation of the full thickness of the bowel wall and can occur in any part of the gastrointestinal tract, from
			the mouth to the anus. Ulcerative colitis causes inflammation of the inner lining of the colon and rectum. Ulcers
			may also form.
		</Par>
		<Par>
			Estimates suggest 61,000 Australians have IBD - approximately 28,000 have Crohn&apos;s disease and 33,000 have
			ulcerative colitis.
		</Par>
		<Par>
			The cause is unknown and treatment depends upon the type of IBD, which part of the gut is affected and how severe
			the disease is.
		</Par>
	</Blog>
);
